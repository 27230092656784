import React from 'react';
import { useHistory } from 'react-router-dom';
import './styles.css';

const CompanyItem = ({data}) => {
  const history = useHistory()


  return (
    <div className='company-item-container' onClick={() => history.push(`/${data.alias}`)}>
      <img className='me-4' src={process.env.REACT_APP_ASSET_URL+data.logo}>

      </img>
      <div className='info ml-5'>
        <h4 style={{lineHeight: '1.5rem', marginBottom: '0.5rem'}}>
          {data.name}
        </h4>
        <h6>
          {data.address}
        </h6>
      </div>
    </div>
  );
}

export default CompanyItem;
