import React, { useContext, useState, useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { SystemContext } from 'contexts';
import ls from 'utils/ls';

const CompanyControl = React.lazy(() => import('views/companyControl'));
const CreateService = React.lazy(() => import('views/createService'));
const UserCalendar = React.lazy(() => import('views/userCalendar'));

const PrivateRoute = ({}) => {
  const [token, setToken] = useState(ls.get('userToken'))
  const systemState = useContext(SystemContext)

  useEffect(() => {
    if(!systemState.state.isLoading) {
      setToken(systemState.state.userToken)
    }
  }, [systemState.state])

  if(token) {
    return (
      <Switch >
        <Route exact path='/app/main' component={UserCalendar}></Route>
        <Route exact path='/app/company' component={CompanyControl}></Route>
        <Route exact path='/app/service' component={CreateService}></Route>
        <Route exact path='/app/service/:id' component={CreateService}></Route>
        <Redirect from='/app' to='/app/main'></Redirect>
      </Switch>
    );
  }
  else {
    return <Redirect to='/'></Redirect>
  }
}

export default PrivateRoute;
