import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import ls from 'utils/ls';
import { SystemContext } from 'contexts';

var timerId

const AxiosComponent = () => {
  const systemState = useContext(SystemContext)

  axios.defaults.baseURL = process.env.REACT_APP_API_URL

  useEffect(() => {

    axios.interceptors.request.use(
      async config => {
        config.timeout = 10000
        if(ls.get('userToken')){
          var token = ls.get('userToken')
        }
        else {
          token = null
        }
        if (token) {
          config.headers.Authorization = "Bearer "+token
        }
        return config
      }
    );

    axios.interceptors.response.use(
      response => {
        if(response.config.method === 'delete') {
          systemState.action.onSuccess('Амжилттай устгалаа!')
        }
        else if(response.config.method === 'post') {
          systemState.action.onSuccess('Амжилттай нэмлээ!')
        }
        else if(response.config.method === 'put') {
          systemState.action.onSuccess('Амжилттай заслаа!')
        }
        return response
      },
      error => {
        console.log(error.response);
        if(error.code === 'ECONNABORTED') {
          console.log(`Timeout happened on url ${error.config.url}`);
        }
        if(error.response) {
          if(error.response.data){
            if(error.response.data.value) {
              if(typeof(error.response.data.value) == 'object') {
                systemState.action.onError(error.response.data.value[0].msg)
              }
              else {
                systemState.action.onError(error.response.data.value)
              }
            }
            else {
              systemState.action.onError('Үйлдэл амжилтгүй боллоо!')
            }
          }
        }
        return Promise.reject(error.response)
      }
    )
  }, [])

  const  throttleFunction  =  function (func, delay) {
  	// If setTimeout is already scheduled, no need to do anything
  	if (timerId) {
  		return
  	}

  	timerId  =  setTimeout(function () {
  		func()
  		timerId  =  undefined;
  	}, 500)
  }

  return null
}

export default AxiosComponent
