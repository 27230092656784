import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Button, Skeleton } from 'antd';
import { ScheduleItem }  from 'components';
import axios from 'axios';
import './styles.css';
import NotFound from 'assets/404.svg';
import NoService from 'assets/no-service.svg';

const Company = ({history}) => {
  const [ data, setData ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const params = useParams()

  useEffect(() => {
    callData()
  }, [])

  const callData = () => {
    axios({
      method: 'get',
      url: `company/${params.company}`
    }).then(res => {
      setData(res.data)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }


  // <div key={i} className='col-12 col-md-6' onClick={() => history.push(`${params.company}/${item.alias}`)}>
  //   <div className='company-appointment-item'>
  //     <div className='company-appointment-title'>
  //       {item.name}
  //     </div>
  //   </div>
  // </div>

  const renderServices = () => {
    let tmp = []

    if(data && !loading) {
      if(data.services.length > 0) {
        data.services.map((item, i) => {
          tmp.push(
            <ScheduleItem key={i} preview data={item} click={() => history.push(`${params.company}/${item.alias}`)}></ScheduleItem>
          )
        })
      }
      else {
        tmp.push(
          <div key={'no-item'} className='d-flex flex-column align-items-center px-5' style={{marginTop: 'auto'}}>
            <img src={NoService} style={{maxWidth: '500px', width: '100%'}}></img>
            <span style={{fontSize: '20px', fontWeight: '600', textAlign: 'center'}}>
              "{data.name}"-д <br/> бүртгэлтэй үйлчилгээ байхгүй байна
            </span>
          </div>
        )
      }
    }
    else {
      tmp.push(
        <div className='d-flex flex-column align-items-center px-5' key={'no-item'}>
          <img src={NotFound} style={{maxWidth: '500px', width: '100%'}}></img>
          <span style={{fontSize: '22px', fontWeight: '600', textAlign: 'center'}}>
            Хуудас олдсонгүй
          </span>
          <br/>
          <Button type='primary' onClick={() => history.push('/')}>
            Нүүр хуудасруу очих
          </Button>
        </div>
      )
    }

    return tmp
  }

  const renderLoading = () => {
    return (
      <>
      <Skeleton>

      </Skeleton>
      <Skeleton>

      </Skeleton>
      <Skeleton>

      </Skeleton>
      </>
    )
  }

  return (
    <div id='company' className='container'>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            {
              loading ?
              renderLoading()
              :
              renderServices()
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Company;
