import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Modal, Tooltip } from 'antd';
import axios from 'axios';

const { confirm } = Modal

const Hours = ({serviceData, hoursData, selectedDate, handleClick}) => {
  const [ clickedHour, setClickedHour ] = useState(null)

  useEffect(() => {
    setClickedHour(null)
  }, [selectedDate])

  const renderHours = () => {
    let tmp = []
    let dayConfig = serviceData.company_hours.filter(item => selectedDate.isoWeekday() == item.dayIndex)
    let startDate = moment(selectedDate.format('YYYY-MM-DD')+' '+dayConfig[0].startTime)
    let endDate = moment(selectedDate.format('YYYY-MM-DD')+' '+dayConfig[0].endTime)
    let now = moment()
    let i = 0

    while(startDate.clone().add(i*serviceData.duration, 'minutes') <= endDate) {
      let clone = startDate.clone().add(i*serviceData.duration, 'minutes')
      let disabled = hoursData.filter(el => (el.appdate.slice(11) == clone.format('HH:mm:00') && el.cnt >= serviceData.capacity)).length > 0 ? 0 : hoursData.filter(el => (el.appdate.slice(11) == clone.format('HH:mm:00') && el.customerstatus == 1)).length > 0 ? 1 : -1

      if(now < clone) {
        tmp.push(
          <Tooltip
            key={clone.format('HH:mm')}
            title={disabled == 0 ? 'Цаг дүүрсэн' : disabled == 1 ? 'Та цаг захиалсан байна' : ''}
            className={clickedHour && (clickedHour.format('HH:mm') == clone.format('HH:mm')) ? 'button-container active' : 'button-container'}>
            <Button className='hour-item' disabled={disabled !== -1} onClick={() => setClickedHour(clone)}>
                <span>
                  {clone.format('HH:mm')}
                </span>
            </Button>
            <Button type='primary' onClick={() => handleOrder(clone)} className='order-button' disabled={clickedHour ? clickedHour.format('HH:mm') !== clone.format('HH:mm') : true}>
              <span>
                Захиалах
              </span>
            </Button>
          </Tooltip>
        )
      }
      i++;
      // startDate.clone().add(serviceData.duration, 'minutes')
    }

    return tmp
  }

  const handleOrder = (date) => {
    confirm({
      title: `Та ${date.format('HH:mm')} энэхүү цагийг захиалах уу?`,
      content: '',
      okText: 'Захиалах',
      cancelText: 'Хаах',
      onOk() {
        // return new Promise((resolve, reject) => {
          handleClick(date)
        // })
      },
      onCancel() {},
    });
  }

  return (
    <div className='hours-container'>
      {
        renderHours()
      }
    </div>
  );
}

export default Hours;
