import React, { useState} from 'react';
import { Input, Checkbox, Button, Form } from 'antd';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import ls from 'utils/ls';
import axios from 'axios';
import { FacebookOutlined } from '@ant-design/icons';

const LoginForm = ({systemState, closeModal, setPage}) => {
  const [ loading, setLoading ] = useState(false)

  const handleResponse = (response) => {
    setLoading(true)
    if(!response.status) {
      axios({
        method: 'post',
        url: 'facebooklogin',
        data: {
          guid : response.userID,
          lastname : response.name,
          firstname : response.name,
          image : response.picture.data.url,
        }
      }).then(res => {
        systemState.action.signIn(res.data.token, res.data)
      }).catch(err => {
        // console.log(err);
      }).then(() => {
        setLoading(false)
        closeModal()
      })
    }
  }

  const onLogin = (values) => {
    setLoading(true)
    axios({
      method: 'post',
      url: `login`,
      data: values
    }).then(res => {
      systemState.action.signIn(res.data.token, res.data)
    }).catch(err => {
      // console.log(err);
    }).then(() => {
      setLoading(false)
      closeModal()
    })
  }

  return (
    <div className='form-container'>
      <Form
        onFinish={onLogin}>
        <Form.Item
          name='email'
          rules={[{ required: true, message: 'Нэвтрэх и-мэйлээ оруулна уу!' }, {type: 'email', message: 'Зөв и-мэйл оруулна уу!'}]}>
          <Input
            placeholder='Нэвтрэх и-мэйл'
            prefix={<UserOutlined className="site-form-item-icon" />}>

          </Input>
        </Form.Item>
        <Form.Item
          name='password'
          rules={[{ required: true, message: 'Нууц үгээ оруулна уу!' }]}>
          <Input
            placeholder='Нууц үг'
            type='password'
            prefix={<LockOutlined className="site-form-item-icon" />}></Input>
        </Form.Item>
        <div className='action-container'>
          {/* <Checkbox>Сануулах</Checkbox> */}
          <Button className='flex-fill' loading={loading} type="primary" htmlType="submit">Нэвтрэх</Button>
        </div>
      </Form>
      <div className='action-container'>
        <Button type='link' onClick={() => setPage(2)}>Бүртгүүлэх</Button>
        <Button type='link' onClick={() => setPage(0)}>Нууц үгээ мартсан</Button>
      </div>
      <div className='divider'>
        <span></span>
        <p>эсвэл</p>
        <span></span>
      </div>
      <FacebookLogin
        appId="474655140358125"
        autoLoad={false}
        fields="name,email,picture"
        disableMobileRedirect={true}
        // onClick={componentClicked}
        render={(renderProps) => (
          <Button loading={loading} onClick={renderProps.onClick} block size={'large'} type='primary' style={{backgroundColor: '#3b5998', borderColor: '#3b5998'}} icon={<FacebookOutlined style={{fontSize: '20px'}}/>}>
            Facebook-ээр нэвтрэх
          </Button>
        )}
        callback={handleResponse} />
    </div>
  );
}

export default LoginForm;
