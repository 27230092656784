import React, { useState } from 'react';
import { Input, Checkbox, Button, Form } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import ls from 'utils/ls';
import axios from 'axios';

const ResetPasswordForm = ({systemState, closeModal, setPage}) => {
  const [ loading, setLoading ] = useState(false)
  const [form] = Form.useForm()

  const handleResponse = (response) => {
    console.log(response);
  }

  const onLogin = (values) => {
    if(values.password !== values.password1) {
      form.setFields([{
        name: 'password1',
        errors: ['Нууц үг таарахгүй байна']
      }]);
    }
    else {
      axios({
        method: 'post',
        url: 'resetpassword',
        data: values
      }).then(res => {
        form.resetFields();
        setPage(0)
      }).catch(err => {
        console.log(err);
      })
    }
  }

  const handleGoBack = () => {
    setPage(1)
    form.resetFields();
  }

  return (
    <div className='form-container'>
      <Form
        form={form}
        onFinish={onLogin}
        initialValues={{
          name: '',
          password: '',
          password1: '',
        }}>
        <Form.Item
          name='email'
          rules={[{type: 'email', message: 'Зөв и-мэйл оруулана уу!'}, { required: true, message: 'Нэвтрэх и-мэйлээ оруулна уу!' }]}>
          <Input
            placeholder='Нэвтрэх и-мэйл'
            prefix={<UserOutlined className="site-form-item-icon" />}>

          </Input>
        </Form.Item>
        <div className='action-container'>
          <Button loading={loading} type='link' onClick={handleGoBack}>Нэвтрэх</Button>
          <Button loading={loading} type="primary" htmlType="submit">Нууц үг сэргээх</Button>
        </div>
      </Form>
    </div>
  );
}

export default ResetPasswordForm;
