import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './styles.css';

import Header from './Header';
import Body from './Body';

const Calendar = ({onDayClick, disabledDays, minDay}) => {
  const [ date, setDate ] = useState(moment())
  const [ test, setTest ] = useState(0)

  const changeMonth = (value) => {
    setTest(test+value)
    setDate(date.clone().add(value, 'months'))
  }

  return (
    <div className='calendar'>
      <Header date={date} changeMonth={changeMonth} test={test}></Header>
      <Body
        date={date}
        onDayClick={onDayClick}
        disabledDays={disabledDays}
        minDay={minDay}>

      </Body>
    </div>
  );
}


Calendar.propTypes = {
  onDateChange: PropTypes.func,
  onDayClick: PropTypes.func,
};

Calendar.defaultProps = {
  onDateChange: () => {},
}

export default Calendar;
