import React from 'react';
import ReactDOM from 'react-dom';
import 'App.less';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import moment from 'moment';
import { SystemContext, SystemProvider } from 'contexts';
import Axios from 'utils/axios';

moment.updateLocale('mn', {
  week: {
    dow: 1
  },
  weekdays: ['Ням', 'Даваа', 'Мягмар', 'Лхагва', 'Пүрэв', 'Баасан', 'Бямба'],
  weekdaysShort: ['Ням', 'Дав', 'Мяг', 'Лха', 'Пүр', 'Баа', 'Бям'],
  weekdaysMin: ['Ня', 'Да', 'Мя', 'Лх', 'Пү', 'Ба', 'Бя'],
  months: ['1-р сар', '2-р сар', '3-р сар', '4-р сар', '5-р сар', '6-р сар', '7-р сар', '8-р сар', '9-р сар', '10-р сар', '11-р сар', '12-р сар'],
})

ReactDOM.render(
  <React.StrictMode>
    <SystemProvider>
      <App />
      <Axios/>
    </SystemProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
