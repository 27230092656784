import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Dropdown, Menu } from 'antd';
import ls from 'utils/ls';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ResetPasswordForm from './ResetPasswordForm';
import { SystemContext } from 'contexts';
import { UserOutlined } from '@ant-design/icons';
import { withRouter, NavLink } from 'react-router-dom';
import Logo from 'assets/24.svg';
import './styles.css';

const Header = ({history}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ scrolled, setScrolled ] = useState(false)
  const [ page, setPage ] = useState(1)
  const systemState = useContext(SystemContext)

  useEffect(() => {
    window.onscroll = () => {
      if(window.pageYOffset > 10) {
        setScrolled(true)
      }
      else {
        setScrolled(false)
      }
    }
  }, [])

  useEffect(() => {
    setIsModalVisible(systemState.state.loginModalOpen)
  }, [systemState.state.loginModalOpen])

  const menu = () => {
    return (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="1" icon={<UserOutlined />}>
          Миний хуваарь
        </Menu.Item>
        <Menu.Item key="2" icon={<UserOutlined />}>
          Байгууллага
        </Menu.Item>
        <Menu.Item key="3" icon={<UserOutlined />}>
          Гарах
        </Menu.Item>
      </Menu>
    )
  }

  const handleMenuClick = (e) => {
    if(e.key == 3) {
      systemState.action.signOut()
    }
    else if (e.key == 2) {
      history.push('/app/company')
    }
    else if (e.key == 1) {
      history.push('/app/main')
    }
  }

  return (
    <header id='header' className={scrolled ? 'shadow-sm glass-header' : 'glass-header'}>
      <div className='container'>
        <NavLink to='/' className='logo-container'>
          <img src={Logo} style={{height: '40px', width: 'auto'}}>

          </img>
        </NavLink>
        {
          systemState.state.userToken ?
          <Dropdown overlay={menu} trigger={['click']}>
            <Button>
              Миний бүртгэл
            </Button>
          </Dropdown>
          :
          <Button onClick={() => systemState.action.loginModalToggle(true)}>
            Нэвтрэх
          </Button>
        }
      </div>
      <Modal
        title={<span style={{fontWeight: '600'}}>{page == 1 ? 'Нэвтрэх' : page == 0 ? "Нууц үг сэргээх" : 'Бүртгүүлэх'}</span>}
        visible={isModalVisible}
        onCancel={() => systemState.action.loginModalToggle(false)}
        footer={null}>
        <div className='forms-container' style={{transform: `translate(calc(${page}*-33% - 24px), 0px)`}}>
          <ResetPasswordForm setPage={setPage} systemState={systemState} closeModal={() => systemState.action.loginModalToggle(false)}></ResetPasswordForm>
          <LoginForm setPage={setPage} systemState={systemState} closeModal={() => systemState.action.loginModalToggle(false)}/>
          <RegisterForm setPage={setPage} systemState={systemState} closeModal={() => systemState.action.loginModalToggle(false)}></RegisterForm>
        </div>
      </Modal>
    </header>
  );
}

export default withRouter(Header);
