import React, { useEffect, useState } from 'react';
import { Input, Row, Col, Select, Button, Icon  } from 'antd';
import axios from 'axios';
import { CompanyItem } from 'components';
import Bg from 'assets/2x.png';
import anime from 'animejs/lib/anime.es.js';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import './styles.css';

const { Search } = Input;
const { Option } = Select

const Home = ({}) => {
  const [ data, setData ] = useState([])
  const [ searchValue, setSearchValue] = useState('')
  const [ cacheSearchValue, setCacheSearchValue ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ page, setPage ] = useState(0)
  const [ categories, setCategories ] = useState([])
  const [ searchType, setSearchType ] = useState(0)
  const [ categoryId, setCategoryId ] = useState(-1)

  useEffect(() => {
    axios({
      method: 'get',
      url: 'category'
    }).then(res => {
      setCategories(res.data)
    }).catch(err => {
      console.log(err);
    })
  }, [])

  const handleSearch = () => {
    if((!searchValue || searchValue == '') && searchType == 0) {
      if(cacheSearchValue !== searchValue) {
        setData([])
        setCacheSearchValue('')
      }
    }
    else if(searchValue !== cacheSearchValue || searchType !== 0) {
      setLoading(true)
      axios({
        method: 'get',
        url: `companysearch/${searchValue.length == 0 ? 'secrettext' : searchValue}?page=${page}&type=${searchType}&categoryId=${categoryId}`
      }).then(res => {
        setData(res.data)
        anime({
          targets: '#home-search-image',
          opacity: 0,
          maxWidth: 0,
          duration: 400,
          easing: 'easeInOutQuad'
        })
      }).catch(err => {
        console.log(err);
      }).then(() => {
        setCacheSearchValue(searchValue)
        setLoading(false)
      })
    }
  }

  const renderCompanies = () => {
    let tmp = []

    if(data.length > 0) {
      data.map((item, i) => {
        tmp.push(
          <div key={i} className='col-12 col-md-8'>
            <CompanyItem data={item}></CompanyItem>
          </div>
        )
      })
    }
    else if( (data.length === 0 && cacheSearchValue.length !== 0) || searchType == 1) {
      tmp.push(
        <div style={{fontWeight: 'bold', textAlign: 'center'}} key={'no-data'}>
          Таны хайсан хайлтанд илэрц олдсонгүй
        </div>
      )
    }

    return tmp
  }

  const renderCategoriesOptions = () => {
    let tmp = []

    categories.map((item, i) => {
      tmp.push(
        <Option key={i} value={item.id}>
          {item.name}
        </Option>
      )
    })

    return tmp;
  }

  return (
    <div id='home' className='page bg-white'>
      <div className='container'>
        <div>
          <img id='home-search-image' src={Bg} style={{display: 'block', width: '100%', height: 'auto', maxWidth: '320px', margin: '0 auto', zIdex: 12}}></img>
        </div>
        <div className='row justify-content-center mb-5'>
          <div className='col-12 col-md-8 search-container'>
            <Input.Group compact>
              <Select style={{width: '120px'}} defaultValue={0} size="large" onChange={(value) => setSearchType(value)}>
                <Option value={0}>Нэрээр</Option>
                <Option value={1}>Төрлөөр</Option>
              </Select>
              {
                searchType == 0 ?
                <Input
                  allowClear
                  onChange={e => setSearchValue(e.target.value)}
                  onKeyUp={(e) => {e.key === 'Enter' && handleSearch()}}
                  size="large"
                  placeholder={'Компаний нэр болон үйлчилгээний төрлөөл хайх'}
                  style={{width: 'calc(100% - 176px)'}}>

                </Input>
                :
                <Select
                  size={'large'}
                  style={{width: 'calc(100% - 176px)'}}
                  defaultValue={-1}
                  onChange={(value) => setCategoryId(value)}>
                  <Option value={-1} key={-1}>Бүгд</Option>
                  {
                    renderCategoriesOptions()
                  }
                </Select>
              }
              <Button
                size='large'
                type='primary'
                style={{width: '56px', padding: '0 12px'}}
                disabled={loading}
                icon={loading ? <LoadingOutlined /> : <SearchOutlined />}
                onClick={handleSearch}>

              </Button>
            </Input.Group>
            {/* <Search
              size="large"
              placeholder="Компаний нэр, үйлчилгээгээр хайх"
              allowClear
              enterButton={loading ? ' ' : 'Хайх'}
              onSearch={handleSearch}
              loading={loading}>

            </Search> */}
          </div>
        </div>
        <div className='row justify-content-center companies-container'>
          {
            renderCompanies()
          }
        </div>
      </div>
    </div>
  );
}

export default Home;
