import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import './styles.css';

const PublicAppointment = ({}) => {
  const [ data, setData ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const params = useParams()

  useEffect(() => {
    axios({
      method: 'get',
      url: `appointment/${params.uuid}`
    }).then(res => {
      console.log(res.data);
      setData(res.data)
    }).catch(err => {
      console.log(err);
    }).then(() => {
      setLoading(false)
    })
  }, [])

  return (
    <div id='public-appointment'>
      <div className='custom-card shadow'>
        {
          loading ?
          <>

          </>
          :
          <div className='info-container'>
            <div className='image-container'>
              <img style={{width: '175px'}} src={process.env.REACT_APP_ASSET_URL+data.service.service_image.image}>

              </img>
            </div>
            <div>
              <h5>
                {data.company.name}
              </h5>
              <h4>
                {data.service.name}
              </h4>
              <h6>
                {moment(data.appdate).format('YYYY-MM-DD')}
              </h6>
              <h4>
                {moment(data.appdate).format('HH:mm')}
              </h4>
            </div>
            <Button className='button-container' danger>
              Цуцлах
            </Button>
          </div>
        }
      </div>
    </div>
  );
}

export default PublicAppointment;
