import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ClockCircleFilled, LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Skeleton } from 'antd';
import axios from 'axios';
import { Calendar } from 'components';
import { Hours, CustomerForm } from './components';
import moment from 'moment';
import anime from 'animejs/lib/anime.es.js';
// import 'react-calendar/dist/Calendar.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { SystemContext } from 'contexts';
import NotFound from 'assets/404.svg';
import './styles.css';

const { confirm } = Modal;
const spinner = <LoadingOutlined style={{ fontSize: 48, color: '#08d4d1' }} spin />;

const Service = ({history}) => {
  const [ data, setData ] = useState(null)
  const [ selectedDate, setSelectedDate ] = useState(null)
  const [ hoursData, setHoursData ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ progress, setProgress ] = useState(0)
  const [ success, setSuccess ] = useState(false)
  const [ selectedHour, setSelectedHour ] = useState(false)
  const [ selectedHour1, setSelectedHour1] = useState(false)
  const [ initLoading, setInitLoading] = useState(true)
  const systemState = useContext(SystemContext)
  const params = useParams()

  useEffect(() => {
    callData()
  }, [])

  const callData = () => {
    setLoading(true)
    axios({
      method: 'get',
      url: `service/search/${params.company}/${params.appointment}`,
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentCompleted)
      }
    }).then(res => {
      setData(res.data)
    }).catch(err => {

    }).then(() => {
      setInitLoading(false)
      setLoading(false)
    })
  }

  const renderHours = () => {
    let tmp = []

    for( var i = 0; i < 10; i++){
      tmp.push(
        <div key={i}>
          {i}
        </div>
      )
    }

    return tmp
  }

  const handleDateClick = (date) => {
    setLoading(true)
    setSelectedDate(date)
    axios({
      method: 'get',
      url: `service/info/${params.company}/${params.appointment}/${date.format('YYYY-MM-DD')}`
    }).then(res => {
      setHoursData(res.data)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
      anime({
        targets: '.hours-container',
        opacity: 1,
        duration: 200,
        easing: 'easeInOutQuad'
      })
    })
  }

  const handleHourClick = (passedDate) => {
    return new Promise((resolve, reject) => {
      if(systemState.state.userToken) {
        setSelectedHour1(passedDate)
        axios({
          method: 'get',
          url: `appointment/checktime/${passedDate.format('YYYY-MM-DDTHH:mm')}`,
        }).then(res => {
          if(typeof(res.data) == 'string') {
            axios({
              method: 'post',
              url: `appointment`,
              data: {
                appointmentDate : passedDate.format('YYYY-MM-DD HH:mm'),
                serviceId : data.id,
              }
            }).then(res => {
              resolve()
              setSuccess(true)
            }).catch(err => {
              reject(err)

            })
          }
          else {
            confirm({
              title: `Таны энэ цагийн хуваарь дээр захиалага байна!`,
              content: `${res.data.company.name} компаний ${res.data.service.name} үйлчилгээ таны хуваарь дээр байна. Тухайн хуваарийг цуцлан энэхүү үйлчилгээг захиалах уу?`,
              okText: 'Цуцлах',
              cancelText: 'Хаах',
              onOk() {
                return new Promise((resolve, reject ) => {
                  axios({
                    method: 'post',
                    url: `customer/cancelappointment`,
                    data: {
                      descr : "Өөр үйлчилгээ авахад цаг давцаж байгаа тул цуцлав.",
                      id : res.data.id
                    }
                  }).then(res => {
                    axios({
                      method: 'post',
                      url: `appointment`,
                      data: {
                        appointmentDate : passedDate.format('YYYY-MM-DD HH:mm'),
                        serviceId : data.id,
                      }
                    }).then(res => {
                      resolve()
                      setSuccess(true)
                    }).catch(err => {

                      reject(err)
                    })
                  }).catch(err => {
                    reject(err)

                  })
                })
              },
              onCancel() {},
            })
          }
        }).catch(err => {
          reject(err)
        })
      }
      else {
        setSelectedHour(passedDate)
        resolve(setSelectedHour1(passedDate))
      }
    })
  }

  const handleBackClick = () => {
    if (selectedDate) {
      setSelectedDate(null)
    }
    else {
      history.push(`/${params.company}`)
    }
  }

  const renderImages = () => {
    let tmp = []

    if(data) {
      data.service_galleries.map((item, i) => {
        tmp.push(
          <div key={`gallery-item-${i}`}>
              <img src={process.env.REACT_APP_ASSET_URL+item.image} />
          </div>
        )
      })
    }

    return tmp
  }

  if(initLoading) {
    return (
      <div id='service' className='container'>
        <div className='row'>
          <div className='col-12'>
            <Skeleton></Skeleton>
            <Skeleton></Skeleton>
            <Skeleton></Skeleton>
          </div>
        </div>
      </div>
    )
  }
  else {
    if(data) {
      return (
        <div id='service' className='container'>
          <div className='card'>
            <div className='card-body p-4 custom-card-body'>
              {
                success ?
                <div className='success-container'>
                  <div>
                    <CheckCircleOutlined className='mb-4' style={{fontSize: '120px'}}/>
                    <h3 style={{marginBottom: 0}}>
                      Таны цаг захиалга амжилттай боллоо.
                    </h3>
                  </div>

                  <div className='d-flex justify-content-center align-items-center info-container'>
                    <div style={{height: '140px'}} className='me-5'>
                      <img style={{height: '100%', width: 'auto'}} src={process.env.REACT_APP_ASSET_URL+data.service_image.image}></img>
                    </div>
                    <div>
                      <h3>
                        {data.company.name}
                      </h3>
                      <h3>
                        {data.name}
                      </h3>
                      <h3>
                        {selectedDate.format('YYYY-MM-DD')} {selectedHour1.format('HH:mm')}
                      </h3>
                    </div>
                  </div>
                </div>
                :
                <div className='row flex-fill h-100'>
                  <div className='col-12 col-md-4 mb-5 mb-md-0 custom-border-right info-container'>
                    <div className='mb-4'>
                      <Button size='large' onClick={handleBackClick}>
                        <i className="fas fa-arrow-left"></i>
                      </Button>
                    </div>
                    {
                      data &&
                      <>
                      <div style={{backgroundImage: `url(${process.env.REACT_APP_ASSET_URL + data.service_image.image})`, backgroundSize: 'contain', backgroundPosition: 'right', backgroundRepeat: 'no-repeat'}}>
                        <div style={{color: 'rgba(77, 80, 85, 0.6)', fontSize: '20px', fontWeight: '600', zIndex: 5}}>
                          {data.company.name}
                        </div>
                        <div style={{fontWeight: 'bold', fontSize: '22px', zIndex: 5}}>
                          {
                            data.name
                          }
                        </div>
                        <div className='d-flex align-items-center' style={{color: 'rgba(77, 80, 85, 0.6)', fontSize: '20px', fontWeight: '600', zIndex: 5}}>
                          <span style={{display: 'flex', marginRight: '0.3rem'}}><ClockCircleFilled></ClockCircleFilled></span>
                          <span>{data.duration} минут</span>
                        </div>
                      </div>

                      <div className='mt-4'>
                        {
                          data.descr
                        }
                      </div>
                      <div>
                        <Carousel
                          autoPlay={true}
                          infiniteLoop={true}
                          renderIndicator={false}
                          stopOnHover={true}
                          showStatus={false}>
                          {
                            renderImages()
                          }
                        </Carousel>
                      </div>
                    </>
                  }
                </div>
                <div className='col-12 col-md-4 custom-border-right'>
                  {
                    (window.innerWidth < 580 && selectedDate ) ?
                    <></>
                    :
                    <Calendar
                      onDayClick={handleDateClick}
                      disabledDays={data ? data.company_hours : []}
                      minDay={moment().add(-1, 'days')}>

                    </Calendar>
                  }
                </div>
                {
                  selectedDate &&
                  <div className='col-12 col-md-4'>
                    <Hours
                      handleClick={handleHourClick}
                      serviceData={data}
                      hoursData={hoursData}
                      selectedDate={selectedDate}>

                    </Hours>
                  </div>
                }
              </div>
            }
          </div>
          <div className='progress-container'>
            <div className='custom-progress' style={progress !== 100 ? {height: '1.5px', width: `${progress}%`} : {height: '0px'}}>

            </div>
          </div>
          {
            loading &&
            <div className='spinner-container'>
              {spinner}
            </div>
          }
        </div>
        <CustomerForm hour={selectedHour} toggleModal={setSelectedHour} setSuccess={setSuccess} serviceId={data && data.id}></CustomerForm>
      </div>
    );
  }
  else {
    return (
      <div id='service' className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='d-flex flex-column align-items-center px-5' key={'no-item'}>
              <img src={NotFound} style={{maxWidth: '500px', width: '100%'}}></img>
              <span style={{fontSize: '22px', fontWeight: '600', textAlign: 'center'}}>
                Хуудас олдсонгүй
              </span>
              <br/>
              <Button type='primary' onClick={() => history.push('/')}>
              Нүүр хуудасруу очих
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
  }
}

export default Service;
