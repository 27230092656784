import React, { Suspense, lazy, useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation, useParams } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import mnMN from 'antd/lib/locale/mn_MN';
import { Header } from 'components';
import { SystemContext, SystemProvider } from 'contexts';
import { PrivateRoute } from 'components';
import ls from 'utils/ls';
import { LoadingOutlined } from '@ant-design/icons';
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";
import Background from 'components/Background';
import Home from 'views/home';
import Company from 'views/company';
import Service from 'views/service';
import ResetPassword from 'views/resetPassword';
import PublicAppointment from 'views/publicAppointment';

const spinner = <LoadingOutlined style={{ fontSize: 24 }} spin />;

// const Home = lazy(() => import('./views/home'));
// const Company = lazy(() => import('./views/company'));
const Search = lazy(() => import('./views/search'));
const Test = lazy(() => import('./views/test'));
// const Service = lazy(() => import('./views/service'));
// const PublicAppointment = lazy(() => import('./views/publicAppointment'))


const pages = [
    { path: '/', order: 1 },
    { path: '/about', order: 2 },
    { path: '/contact', order: 3 }
]

const App = ({}) => {
  const [token, setToken] = useState(ls.get('userToken'))
  const systemState = useContext(SystemContext)

  useEffect(() => {
    if(!systemState.state.isLoading) {
      setToken(systemState.state.userToken)
    }
  }, [systemState.state])

  return (
    <ConfigProvider locale={mnMN}>
      <Router>
        <Suspense fallback={
          <div style={{position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, display: 'flex', justify: 'center', alignItems: 'center'}}>
            {
              spinner
            }
          </div>}>
          <Header>

          </Header>
          <Switch>
            <Route path="*">
              <AnimationApp></AnimationApp>
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </ConfigProvider>
  );
}

const AnimationApp = () => {
  const location = useLocation()
  const timeout = { enter: 800, exit: 400 };
  const [ prevDepth, setPrevDepth ] = useState(0)

  useEffect(() => {
    setPrevDepth(getPathDepth(location))
  }, [location])

  const getPathDepth = (location) => {
    let pathArr = location.pathname.split("/");
    pathArr = pathArr.filter(n => n !== "");
    return pathArr.length;
  }

  return (
    <Switch>
      <Route exact path='/' component={Home}/>
      <Route exact path='/search/:search' component={Search}/>
      <Route path='/app' render={props => <PrivateRoute {...props}/>}/>
      <Route path='/resetpassword/:guid' render={props => <ResetPassword {...props}/>}/>
      <Route path='/appointment/:uuid' component={PublicAppointment}></Route>
      {/* <Route path='/test' component={Test}/> */}
      <Route exact path='/:company' component={Company}/>
      <Route path='/:company/:appointment' component={Service}/>
    </Switch>
  )

  // return (
  //   <>
  //     <TransitionGroup component="div" className="App">
  //       <CSSTransition
  //         key={location.key}
  //         timeout={timeout}
  //         classNames="pageSlider"
  //         mountOnEnter={false}
  //         unmountOnExit={true}>
  //         <div className={ getPathDepth(location) - prevDepth >= 0 ? "left" : "right" }>
  //           <Switch>
  //             <Route exact path='/' component={Home}/>
  //             <Route exact path='/search/:search' component={Search}/>
  //             <Route path='/app' render={props => <PrivateRoute {...props}/>}/>
  //             <Route path='/appointment/:uuid' component={PublicAppointment}></Route>
  //             {/* <Route path='/test' component={Test}/> */}
  //             <Route exact path='/:company' component={Company}/>
  //             <Route path='/:company/:appointment' component={Service}/>
  //           </Switch>
  //         </div>
  //       </CSSTransition>
  //       {/* <Background rotate={prevDepth}>
  //
  //       </Background> */}
  //     </TransitionGroup>
  //   </>
  // )
}

export default App;
