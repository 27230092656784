import React, { useReducer } from 'react';
import ls from 'utils/ls';
import { message } from 'antd';

const SystemContext = React.createContext()

const initialState = {
  isLoading: true,
  isSignout: false,
  userToken: null,
  loginModalOpen: false
};

const reducer = (prevState, action) => {
  switch (action.type) {
    case 'RESTORE_TOKEN':
      return {
        ...prevState,
        userToken: action.token,
        isLoading: false
      };
    case 'SIGN_IN':
      return {
        ...prevState,
        isSignout: false,
        userToken: action.token,
      };
    case 'SIGN_OUT':
      return {
        ...prevState,
        isSignout: true,
        userToken: null,
      };
    case 'LOGIN_MODAL_TOGGLE':
      return {
        ...prevState,
        loginModalOpen: action.modalOpen
      }
  }
  return prevState;
};

const SystemProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  React.useLayoutEffect(() => {
    const bootstrapAsync = async () => {
      let userToken;
      let userInfo;
      try {
        userToken = ls.get('userToken');
        userInfo = ls.get('userInfo')

        dispatch({ type: 'RESTORE_TOKEN', token: userToken });
      } catch (e) {
        console.log(e);
      }
    };

    bootstrapAsync();
  }, []);

  const actions = React.useMemo(
    () => ({
      signIn: async (token, info) => {
        ls.set('userToken', token)
        ls.set('userInfo', {...info})
        dispatch({ type: 'SIGN_IN', token: token });
      },
      signOut: async () => {
        ls.remove('userToken')
        dispatch({ type: 'SIGN_OUT' })
      },
      changeUpdateLoading: async (status) => {
        dispatch({type: 'CHANGE_UPDATE_LOADING', status: status})
      },
      loginModalToggle: async (modalOpen) => {
        dispatch({type: 'LOGIN_MODAL_TOGGLE', modalOpen: modalOpen})
      },
      onSuccess: (info) => {
        message.success(info)
      },
      onError: (info) => {
        message.error(info)
      }
    }),
    []
  );

  return (
    <SystemContext.Provider value={{state: state, action: actions}}>
      {props.children}
    </SystemContext.Provider>
  )
}

export {
  SystemContext,
  SystemProvider
}
