import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const Body = ({date, onDayClick, disabledDays, minDay}) => {
  const [ actviveDate, setActiveDate ] = useState(null)

  const renderMonthDays = () => {
    let tmp = []
    for(let i = 1; i < date.startOf('month').isoWeekday(); i++) {
      tmp.push(
        <div key={`filler-${i+1}`} className='day disabled'>

        </div>
      )
    }

    for(let i = 0 ; i < date.daysInMonth(); i++) {
      let day = date.clone().startOf('month').add(i, 'days')
      var classname = 'day'
      if(disabledDays.findIndex(item => item.dayIndex == (date.clone().startOf('month').add(i, 'days').day()) && item.startTime !== null) == -1){
        classname += ' disabled'
      }
      if(actviveDate) {
        if(date.clone().startOf('month').add(i, 'days').format('YYYY-MM-DD') == actviveDate.format('YYYY-MM-DD')) {
          classname += ' selected'
        }
      }
      if(minDay > day) {
        classname += ' disabled'
      }
      tmp.push(
        <div key={`${i}`} className={classname} onClick={(e) => {handleDayClick(i)}}>
          <span className='day-text'>
            {day.format('D')}
          </span>
        </div>
      )
    }

    return tmp
  }

  const handleDayClick = (i) => {
    let tmp = date.clone().startOf('month').add(i, 'days')
    setActiveDate(tmp)
    onDayClick(tmp)
  }

  return (
    <div className='calendar-body'>
      <div className='week' key={`week-days`}>
        <div> Да </div>
        <div> Мя </div>
        <div> Лх </div>
        <div> Пү </div>
        <div> Ба </div>
        <div> Бя </div>
        <div> Ня </div>
      </div>
      <div className='month-days'>
        {
          renderMonthDays()
        }
      </div>
    </div>
  );
}

export default Body;
