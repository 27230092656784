import React, {useEffect} from 'react';
import moment from 'moment';

const Header = ({date, changeMonth, test}) => {

  return (
    <div className='calendar-header'>
      <span className='title'>
        {date.format('YYYY оны MMMM')}
      </span>
      <div className='header-buttons'>
        <button className='prev-button' onClick={() => changeMonth(-1)}>
          <i className="fas fa-chevron-left"></i>
        </button>
        <button className='next-button' onClick={() => changeMonth(1)}>
          <i className="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
}

export default Header;
