import React, { useState } from 'react';
import { Modal, Form, Button, Input } from 'antd';
import axios from 'axios';

const layout = {
  labelCol: { xs: { span: 24 }, sm: { span: 12 }, md: { span: 8 }, lg: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 12 }, md: { span: 12 }, lg: { span: 16 } }
};

const tailLayout = {
  wrapperCol: { xs: { offset: 0, spane: 24}, md: {offset: 8, span: 16} },
};

const validateMessages = {
  required: '${label} заавал оруулна уу!'
}

const CustomerForm = ({hour, toggleModal, serviceId, setSuccess}) => {
  const [ form ] = Form.useForm()
  const [ loading, setLoading ] = useState(false)
  const [ data, setData ] = useState({
    name : "",
    mobile : "",
    email : "",
    descr : ""
  })

  const handleFinish = (values) => {
    setLoading(true)
    axios({
      method: 'post',
      url: `appointment`,
      data: {
        ...values,
        appointmentDate: hour.format('YYYY-MM-DD HH:mm:ss'),
        serviceId: serviceId
      }
    }).then(res => {
      setSuccess(true)
    }).catch(err => {
      //
    }).then(() => {
      setLoading(false)
      toggleModal(false)
    })
  }

  return (
    <Modal
      title={<span style={{fontWeight: '600'}}>Таны мэдээлэл</span>}
      visible={typeof(hour) !== 'boolean'}
      footer={false}
      onCancel={() => loading ? {} : toggleModal(false)}>
      <Form
        {...layout}
        initialValues={data}
        form={form}
        validateMessages={validateMessages}
        onFinish={handleFinish}>
        <Form.Item name='name' label='Нэр' rules={[{required: true}]}>
          <Input>

          </Input>
        </Form.Item>
        <Form.Item name='email' label='И-мэйл' rules={[{required: true}, {type: 'email', message: 'Зөв и-мэйл оруулна уу!'}]}>
          <Input>

          </Input>
        </Form.Item>
        <Form.Item name='mobile' label='Утас' rules={[{required: true}]}>
          <Input>

          </Input>
        </Form.Item>
        <Form.Item name='descr' label='Нэмэлт мэдээлэл'>
          <Input.TextArea>

          </Input.TextArea>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <div className='d-flex justify-content-end'>
            <Button loading={loading} type="primary" danger style={{marginRight: '1rem'}} onClick={() => toggleModal(false)}>
              Буцах
            </Button>
            <Button loading={loading} type="primary" htmlType='submit'>
              Захиалах
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CustomerForm;
