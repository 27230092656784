import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Switch , Button, Dropdown, Menu, message } from 'antd';
import './styles.css';
import {
  SettingFilled,
  CaretDownOutlined
} from '@ant-design/icons';
import axios from 'axios';
import Color from 'color';

const ScheduleItem = ({data, index, preview, click, refreshData, history, handleCreateQR, handleImageClick}) => {
  const [ clicked, setClicked ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ rotate, setRotate ] = useState(0)

  const menu = () => {
    return (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="1">
          Засах
        </Menu.Item>
        <Menu.Item key="2">
          Зураг оруулах
        </Menu.Item>
        <Menu.Item key="3">
          Устгах
        </Menu.Item>
      </Menu>
    )
  }

  const handleMenuClick = (e) => {
    if(e.key == 1) {
      history.push(`/app/service/${data.id}`)
    }
    if(e.key == 2) {
      handleImageClick({...data, index: index})
    }
    if(e.key == 3) {
      axios({
        method: 'delete',
        url: `service/${data.id}`
      }).then(res => {
        console.log(res.data);
        refreshData()
      }).catch(err => {
        console.log(err);
      })
    }
  }

  const ToggleServiceStatus = (e) => {
    setLoading(true)
    axios({
      method: 'post',
      url: `myservices/changestatus`,
      data: {
        id: data.id,
        status: e ? 1 : 0
      }
    }).then(res => {
      console.log(res.data);
    }).catch(err => {
      console.log(err);
    }).then(() => {
      setLoading(false)
    })
  }

  const handleChange = () => {
    setRotate(rotate == 0 ? 180 : 0)
  }

  const copyTextToClipboard = () => {
    if (!navigator.clipboard) {
      // fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(`https://2400.mn/${data.company.alias}/${data.alias}`).then(function() {
      //
      message.success('Амжилттай хууллаа!')

    }, function(err) {
      // console.error('Async: Could not copy text: ', err);
      message.error('Хуулахад алдаа гарлаа!')
    });
  }

  return (
    <div className='col-12 col-md-4 mb-4 px-4' onClick={click && click} style={click && {cursor: 'pointer'}}>
      <div className='schedule-item-container'>
        <div className='schedule-cap' style={{backgroundColor: data.color}}>

        </div>
        <div className='schedule-cap schedule-cap-light' style={{backgroundColor: Color(data.color).lighten(0.3)}}>

        </div>
        {
          !preview &&
          <div className='schedule-controller mb-4'>
            <Switch onChange={ToggleServiceStatus} checkedChildren="Идэвхитэй" unCheckedChildren="Идэвхигүй" defaultChecked={data.status == 'Идэвхтэй' ? true : false} disabled={loading}>

            </Switch>
            <Dropdown overlay={menu} trigger={['click']} onVisibleChange={handleChange}>
              <Button type="text" onClick={() => {setClicked(!clicked)}}>
                <SettingFilled style={{color: '#a0a0a0'}}></SettingFilled>
                <CaretDownOutlined style={{color: '#a0a0a0'}} rotate={rotate}></CaretDownOutlined>
              </Button>
            </Dropdown>
          </div>
        }
        <div className='info-container'>
          <div>
            <h4 style={{fontWeight: '600'}}>
              {data.name}
            </h4>
            <p style={{fontWeight: '600'}}>
              {data.duration} минут <br/> {data.capacity} хүн зэрэг
            </p>
            <p style={{fontWeight: '600'}}>
              {data.price} ₮
            </p>
          </div>
          <img src={process.env.REACT_APP_ASSET_URL+data.service_image.image}>

          </img>
        </div>
        {
          !preview &&
          <div className='schedule-actions-container'>
            <span className='divider'></span>
            <Button type='link' onClick={copyTextToClipboard}>Холбоос хуулах</Button>
            <Button type='link' onClick={() => handleCreateQR(data)}>КиүАр код үүсгэх</Button>
          </div>
        }
      </div>
    </div>
  );
}

export default ScheduleItem;
