import React, { useState, useEffect} from 'react';
import { Skeleton, Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useParams, useHistory } from 'react-router-dom';
import NotFound from 'assets/404.svg';
import axios from 'axios';

const ResetPassword = ({}) => {
  const [ loading, setLoading ] = useState(true)
  const [ error, setError ] = useState(true)
  const [ form ] = Form.useForm()
  const params = useParams()
  const history = useHistory()

  useEffect(() => {
    axios({
      method: 'get',
      url: `resetpassword/${params.guid}`
    }).then(res => {
      setError(false)
    }).catch(err => {
      console.log(err);
    }).then(() => {
      setLoading(false)
    })
  }, [])

  const handleSumbit = (values) => {
    if(values.password !== values.password1) {
      form.setFields([{
        name: 'password1',
        errors: ['Нууц үг таарахгүй байна']
      }]);
    }
    else {
      axios({
        method: 'post',
        url: `resetpassword/reset/${params.guid}`,
        data: {
          password: values.password
        }
      }).then(res => {
        form.resetFields();
        history.replace('/')
      }).catch(err => {
        console.log(err);
      })
    }
  }


  return (
    <div className='container' style={{paddingTop: '100px'}}>
      {
        loading ?
        <Skeleton></Skeleton>
        :
        error ?
        <div className='d-flex flex-column align-items-center px-5' key={'no-item'}>
          <img src={NotFound} style={{maxWidth: '500px', width: '100%'}}></img>
          <span style={{fontSize: '22px', fontWeight: '600', textAlign: 'center'}}>
            Холбоосны идэвхитэй хугацаа дууссан байна
          </span>
          <br/>
          <Button type='primary' onClick={() => history.push('/')}>
            Нүүр хуудасруу очих
          </Button>
        </div>
        :
        <Form
          form={form}
          onFinish={handleSumbit}>
          <Form.Item
            name='password'
            rules={[{ required: true, message: 'Нууц үгээ оруулна уу!' }]}>
            <Input
              placeholder='Нууц үг'
              type='password'
              prefix={<LockOutlined className="site-form-item-icon" />}>

            </Input>
          </Form.Item>
          <Form.Item
            name='password1'
            dependencies={['password']}
            rules={[{ required: true, message: 'Нууц үгээ оруулна уу!' }]}>
            <Input
              placeholder='Нууц үг давтах'
              type='password'
              prefix={<LockOutlined className="site-form-item-icon" />}>

            </Input>
          </Form.Item>
          <div className='action-container'>
            <Button className='w-100' loading={loading} type="primary" htmlType="submit">Солих</Button>
          </div>
        </Form>
      }
    </div>
  );
}

export default ResetPassword;
